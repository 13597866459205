<nav class="navbar navbar-default navbar-fixed-top">
  <div class="logo-container">
    <img routerLink="" id="logo_image" alt="Logo" />
  </div>

  <a [href]="adminUrl" target="_blank" class="redirection-icon-container" [ngStyle]="{'background-color': style['s']}">
    <mat-icon>exit_to_app</mat-icon>
  </a>
</nav>

<div class="container">
  <div class="d-flex justify-content-center" (click)="listeAnzeigen()">
    <p id="IV_header" class="font-1" *ngIf="style" [ngStyle]="{'background-color': style['s']}">
      {{text['P0_S_Banner']}}
    </p>
  </div>

  <div>
    <router-outlet></router-outlet>

    <div class="links">
      <app-imprint-and-data-protection-links></app-imprint-and-data-protection-links>
    </div>
  </div>
</div>
