import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {StyleService} from '../../services/style.service';
import {StyleModel} from '../../models/style.model';

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss']
})

export class MessageDialogComponent implements OnInit {
  public message: string;
  public title: string;
  public confirmButton: string;
  public style: StyleModel;

  constructor(
    public dialogRef: MatDialogRef<MessageDialogComponent>,
    private _styleService: StyleService
  ) {}

  ngOnInit(): void {
    this._styleService.styleObservable.subscribe((style: StyleModel) => {
      this.style = style;
    });
  }
}
