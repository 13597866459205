<button
  id="modalbtn"
  type="button"
  class="btn btn-info btn-lg"
  data-toggle="modal"
  data-target="#ModalDataProtection"
  [hidden]="true">
  Open Modal
</button>

<div id="ModalDataProtection" class="modal fade" role="dialog" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header text-center">
                <p class="font-2 text-center">Qualifizierter Installateur:</p>
            </div>

            <div class="modal-body" style="padding:10px;" *ngIf="contact != null">
                <div>
                    <ng-container *ngIf="contact.contractDetails">
                        <div class="row" *ngFor="let contract of contact.contractDetails">
                            <div class="col-6">
                                <span class="t-left">{{contract.contractTitle}}</span>
                            </div>
                            <div class="col-6">
                                <span class="t-right">{{contract.contractNumber}}</span>
                            </div>
                        </div>
                    </ng-container>
                    <div class="row" *ngIf="contact.salutation">
                        <div class="col-6">
                            <span class="t-left">Anrede:</span>
                        </div>
                        <div class="col-6 ">
                            <span class="t-right">{{ contact.salutation == 1 ? 'Frau' : 'Herr' }}</span>
                        </div>
                    </div>
                    <div class="row" *ngIf="contact.title">
                        <div class="col-6">
                            <span class="t-left">Titel:</span>
                        </div>
                        <div class="col-6">
                            <span class="t-right">{{ contact.title }}</span>
                        </div>
                    </div>
                    <div class="row" *ngIf="contact.firstName">
                        <div class="col-6">
                            <span class="t-left">Vorname:</span>
                        </div>
                        <div class="col-6">
                            <span class="t-right">{{ contact.firstName }}</span>
                        </div>
                    </div>
                    <div class="row" *ngIf="contact.lastName">
                        <div class="col-6">
                            <span class="t-left">Nachname:</span>
                        </div>
                        <div class="col-6">
                            <span class="t-right">{{ contact.lastName }}</span>
                        </div>
                    </div>
                    <div class="row" *ngIf="contact.phoneNumber">
                        <div class="col-6">
                            <span class="t-left">Telefonnummer:</span>
                        </div>
                        <div class="col-6">
                            <span class="t-right"><a href="tel:contact.phoneNumber">{{ contact.phoneNumber }}</a></span>
                        </div>
                    </div>
                    <div class="row" *ngIf="contact.mobileNumber">
                        <div class="col-6">
                            <span class="t-left">Mobilnummer:</span>
                        </div>
                        <div class="col-6">
                            <span class="t-right"><a
                                    href="tel:contact.mobileNumber">{{ contact.mobileNumber }}</a></span>
                        </div>
                    </div>
                    <div class="row" *ngIf="contact.mail">
                        <div class="col-6">
                            <span class="t-left">E-Mail Adresse:</span>
                        </div>
                        <div class="col-6">
                            <span class="t-right"><a [href]="'mailto:' + contact.mail">{{ contact.mail }}</a></span>
                        </div>
                    </div>
                    <div class="row" style="margin-top:25px;">
                        <div class="col-12">
                            <button type="button" class="btn btn-info btn-full-size btn-prev-step" data-toggle="modal"
                                data-target="#ModalDataProtection"
                                [style.backgroundColor]="this.style && this.style['p']">
                                Schließen
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="d-flex justify-content-center mt-4">
    <div class="card text-center wizard-card">
        <div class="card-header wizard-card-header">
            <p class="font-6">Gelistete qualifizierte Installateure</p>
        </div>
        <div class="card-body wizard-card-body">
            <div class="container table-style">
                <div class="filters-container">
                    <mat-form-field>
                      <input matInput (keyup)="onSearchChange($event.target['value'])" placeholder="Suche"/>
                    </mat-form-field>

                    <mat-form-field>
                      <mat-select [ngModel]="filter.applicationTypeFilter" (selectionChange)="onApplicationTypeFilterChange($event.value)">
                        <mat-option [value]="0">Alle</mat-option>
                        <mat-option [value]="1">Netzinstallateure</mat-option>
                        <mat-option [value]="2">Gastinstallateure</mat-option>
                      </mat-select>
                    </mat-form-field>
                </div>

                <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort matSortActive="company" matSortDirection="asc">
                    <ng-container matColumnDef="company">
                        <th *matHeaderCellDef mat-header-cell mat-sort-header>Firma</th>
                        <td mat-cell *matCellDef="let element">{{ element.company }}</td>
                    </ng-container>

                    <ng-container matColumnDef="approvedSections">
                        <th *matHeaderCellDef mat-header-cell>Genehmigt</th>
                        <td mat-cell *matCellDef="let element">{{ element.appovedSections }}</td>
                    </ng-container>

                    <ng-container matColumnDef="address">
                        <th *matHeaderCellDef mat-header-cell mat-sort-header>Anschrift</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.address + ', ' + element.zipCode + ' ' + element.city }}</td>
                    </ng-container>

                    <ng-container matColumnDef="details">
                        <th *matHeaderCellDef mat-header-cell>Kontakt</th>
                        <td mat-cell *matCellDef="let element">
                            <button
                              class="btn btn-next-step table-button"
                              (click)="showModal(element.details)"
                              *ngIf="element.contactType !== null"
                              [style.backgroundColor]="this.style && this.style['p']">
                              Kontakt
                            </button>
                        </td>
                    </ng-container>

                    <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
                    <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
                </table>
            </div>
        </div>
    </div>
</div>
