<div class="d-flex justify-content-center mt-4">
  <div class="card text-center wizard-card">
    <div class="card-header wizard-card-header">
      <p class="font-2">{{text['P14_Headline']}}</p>
      <p class="font-3">{{text['P14_Info']}}</p>
      <app-info-modal></app-info-modal>
    </div>

    <div class="card-body wizard-card-body">
      <div class="row">
        <div class="col-sm-12 col-md-12 mt-2 mb-3 wizard-col">
          <div *ngIf="aData['isGuest']">
            <p class="font-4">{{text['P14_Gast']}}</p>
            <div class="data-div">
              <div class="card conclusion-card">
                <div class="card-header conclusion-card-header" [ngStyle]="{'background-color': style['s']}" id="headingCompany" *ngIf="companyToggle && style">
                  <h5 class="mb-0">
                    <button (click)="onCollapseableClick(1)" class="btn btn-link conclusion-card-button font-1 conclusion-card-header-button" data-toggle="collapse" data-target="#companyDiv" aria-expanded="false" aria-controls="companyDiv"><i class="fas fa-arrow-down conclusion-card-icon"></i>{{ text['P14_Button_Toggle_Unternehmen_1'] }}</button>
                  </h5>
                </div>

                <div class="card-header conclusion-card-header-active" [ngStyle]="{'background-color': style['p']}" id="headingCompany" *ngIf="!companyToggle">
                  <h5 class="mb-0">
                    <button (click)="onCollapseableClick(1)" class="btn btn-link conclusion-card-button font-1 conclusion-card-header-button" data-toggle="collapse" data-target="#companyDiv" aria-expanded="false" aria-controls="companyDiv"><i class="fas fa-arrow-up conclusion-card-icon"></i>{{ text['P14_Button_Toggle_Unternehmen_2'] }}</button>
                  </h5>
                </div>

                <div id="companyDiv" class="collapse" aria-labelledby="headingCompany">
                  <div class="card-body">
                    <table class="table">
                      <tbody>
                        <tr>
                          <td class="td-left">Name:</td>
                          <td class="td-right">{{ aData['cName'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Straße:</td>
                          <td class="td-right">{{ aData['cStreet'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Hausnummer:</td>
                          <td class="td-right">{{ aData['cHouseNumber'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Postleitzahl:</td>
                          <td class="td-right">{{ aData['cZip'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Stadt:</td>
                          <td class="td-right">{{ aData['cCity'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Telefon:</td>
                          <td class="td-right">{{ aData['cPhoneNumber'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">E-Mail Adresse:</td>
                          <td class="td-right">{{ aData['cMail'] }}</td>
                        </tr>
                        <tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div class="data-div">
              <div class="card conclusion-card">
                <div class="card-header conclusion-card-header" [ngStyle]="{'background-color': style['s']}" id="headingWorkshop" *ngIf="workshopToggle">
                  <h5 class="mb-0">
                    <button (click)="onCollapseableClick(2)" class="btn btn-link conclusion-card-button font-1 conclusion-card-header-button" data-toggle="collapse" data-target="#workshopDiv" aria-expanded="false" aria-controls="workshopDiv"><i class="fas fa-arrow-down conclusion-card-icon"></i>{{ text['P14_Button_Toggle_Werkstatt_1'] }}</button>
                  </h5>
                </div>

                <div class="card-header conclusion-card-header-active" [ngStyle]="{'background-color': style['p']}" id="headingWorkshop" *ngIf="!workshopToggle">
                  <h5 class="mb-0">
                    <button (click)="onCollapseableClick(2)" class="btn btn-link conclusion-card-button font-1 conclusion-card-header-button" data-toggle="collapse" data-target="#workshopDiv" aria-expanded="false" aria-controls="workshopDiv"><i class="fas fa-arrow-up conclusion-card-icon"></i>{{ text['P14_Button_Toggle_Werkstatt_2'] }}</button>
                  </h5>
                </div>

                <div id="workshopDiv" class="collapse" aria-labelledby="headingWorkshop">
                  <div class="card-body">
                    <table *ngIf="!aData['wToggle']" class="table">
                      <tbody>
                        <tr>
                          <td class="td-left">Name:</td>
                          <td class="td-right">{{ aData['cName'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Straße:</td>
                          <td class="td-right">{{ aData['cStreet'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Hausnummer:</td>
                          <td class="td-right">{{ aData['cHouseNumber'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Postleitzahl:</td>
                          <td class="td-right">{{ aData['cZip'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Stadt:</td>
                          <td class="td-right">{{ aData['cCity'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Werkstatt eingerichtet:</td>
                          <td class="td-right">{{ aData['wFitted'] == true ? 'Ja' : 'Nein' }}</td>
                        </tr>
                        <tr>
                      </tbody>
                    </table>

                    <table *ngIf="aData['wToggle']" class="table">
                      <tbody>
                        <tr>
                          <td class="td-left">Name:</td>
                          <td class="td-right">{{ aData['wName'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Straße:</td>
                          <td class="td-right">{{ aData['wStreet'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Hausnummer:</td>
                          <td class="td-right">{{ aData['wHouseNumber'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Postleitzahl:</td>
                          <td class="td-right">{{ aData['wZip'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Stadt:</td>
                          <td class="td-right">{{ aData['wCity'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Werkstatt eingerichtet:</td>
                          <td class="td-right">{{ aData['wFitted'] == true ? 'Ja' : 'Nein' }}</td>
                        </tr>
                        <tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div class="data-div">
              <div class="card conclusion-card">
                <div class="card-header conclusion-card-header" [ngStyle]="{'background-color': style['s']}" id="headingOwner" *ngIf="ownerToggle">
                  <h5 class="mb-0">
                    <button (click)="onCollapseableClick(3)" class="btn btn-link conclusion-card-button font-1 conclusion-card-header-button" data-toggle="collapse" data-target="#ownerDiv" aria-expanded="false" aria-controls="ownerDiv"><i class="fas fa-arrow-down conclusion-card-icon"></i>{{ text['P14_Button_Toggle_Inhaber_1'] }}</button>
                  </h5>
                </div>

                <div class="card-header conclusion-card-header-active" [ngStyle]="{'background-color': style['p']}" id="headingOwner" *ngIf="!ownerToggle">
                  <h5 class="mb-0">
                    <button (click)="onCollapseableClick(3)" class="btn btn-link conclusion-card-button font-1 conclusion-card-header-button" data-toggle="collapse" data-target="#ownerDiv" aria-expanded="false" aria-controls="ownerDiv"><i class="fas fa-arrow-up conclusion-card-icon"></i>{{ text['P14_Button_Toggle_Inhaber_2'] }}</button>
                  </h5>
                </div>

                <div id="ownerDiv" class="collapse" aria-labelledby="headingOwner">
                  <div class="card-body">
                    <table class="table">
                      <tbody>
                        <tr>
                          <td class="td-left">Anrede:</td>
                          <td class="td-right">{{ aData['oSalutation'] == 1 ? 'Frau' : 'Herr' }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Titel:</td>
                          <td class="td-right">{{ aData['oTitle'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Vorname:</td>
                          <td class="td-right">{{ aData['oFirstName'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Nachname:</td>
                          <td class="td-right">{{ aData['oLastName'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Telefon:</td>
                          <td class="td-right">{{ aData['oPhoneNumber'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Mobil:</td>
                          <td class="td-right">{{ aData['oMobileNumber'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">E-Mail:</td>
                          <td class="td-right">{{ aData['oMail'] }}</td>
                        </tr>
                        <tr>
                      </tbody>
                    </table>

                  </div>
                </div>
              </div>


            </div>

            <div class="data-div">
              <div class="card conclusion-card">
                <div class="card-header conclusion-card-header" [ngStyle]="{'background-color': style['s']}" id="headingExpert" *ngIf="expertToggle">
                  <h5 class="mb-0">
                    <button (click)="onCollapseableClick(4)" class="btn btn-link conclusion-card-button font-1 conclusion-card-header-button" data-toggle="collapse" data-target="#expertDiv" aria-expanded="false" aria-controls="expertDiv"><i class="fas fa-arrow-down conclusion-card-icon"></i>{{ text['P14_Button_Toggle_Fachkraft_1'] }}</button>
                  </h5>
                </div>

                <div class="card-header conclusion-card-header-active" [ngStyle]="{'background-color': style['p']}" id="headingExpert" *ngIf="!expertToggle">
                  <h5 class="mb-0">
                    <button (click)="onCollapseableClick(4)" class="btn btn-link conclusion-card-button font-1 conclusion-card-header-button" data-toggle="collapse" data-target="#expertDiv" aria-expanded="false" aria-controls="expertDiv"><i class="fas fa-arrow-up conclusion-card-icon"></i>{{ text['P14_Button_Toggle_Fachkraft_2'] }}</button>
                  </h5>
                </div>

                <div id="expertDiv" class="collapse" aria-labelledby="headingExpert">
                  <div class="card-body">
                    <table class="table" *ngIf="aData['eToggleState']">
                      <tbody>
                        <tr>
                          <td class="td-left">Anrede:</td>
                          <td class="td-right">{{ aData['oSalutation'] == 1 ? 'Frau' : 'Herr' }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Titel:</td>
                          <td class="td-right">{{ aData['oTitle'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Vorname:</td>
                          <td class="td-right">{{ aData['oFirstName'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Nachname:</td>
                          <td class="td-right">{{ aData['oLastName'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Telefon:</td>
                          <td class="td-right">{{ aData['oPhoneNumber'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Mobil:</td>
                          <td class="td-right">{{ aData['oMobileNumber'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">E-Mail:</td>
                          <td class="td-right">{{ aData['oMail'] }}</td>
                        </tr>
                        <tr>
                      </tbody>
                    </table>

                    <table class="table" *ngIf="!aData['eToggleState']">
                      <tbody>
                        <tr>
                          <td class="td-left">Anrede:</td>
                          <td class="td-right">{{ aData['eSalutation'] == 1 ? 'Frau' : 'Herr' }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Titel:</td>
                          <td class="td-right">{{ aData['eTitle'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Vorname:</td>
                          <td class="td-right">{{ aData['eFirstName'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Nachname:</td>
                          <td class="td-right">{{ aData['eLastName'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Telefon:</td>
                          <td class="td-right">{{ aData['ePhoneNumber'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Mobil:</td>
                          <td class="td-right">{{ aData['eMobileNumber'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">E-Mail:</td>
                          <td class="td-right">{{ aData['eMail'] }}</td>
                        </tr>
                        <tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div class="data-div">
              <div class="card conclusion-card">
                <div class="card-header conclusion-card-header" [ngStyle]="{'background-color': style['s']}" id="headingIA" *ngIf="iaToggle">
                  <h5 class="mb-0">
                    <button (click)="onCollapseableClick(12)" class="btn btn-link conclusion-card-button font-1 conclusion-card-header-button" data-toggle="collapse" data-target="#iaDiv" aria-expanded="false" aria-controls="iaDiv"><i class="fas fa-arrow-down conclusion-card-icon"></i>{{ text['P14_Button_Toggle_Installateurausweis_1'] }}</button>
                  </h5>
                </div>

                <div class="card-header conclusion-card-header-active" [ngStyle]="{'background-color': style['p']}" id="headingIA" *ngIf="!iaToggle">
                  <h5 class="mb-0">
                    <button (click)="onCollapseableClick(12)" class="btn btn-link conclusion-card-button font-1 conclusion-card-header-button" data-toggle="collapse" data-target="#iaDiv" aria-expanded="false" aria-controls="iaDiv"><i class="fas fa-arrow-up conclusion-card-icon"></i>{{ text['P14_Button_Toggle_Installateurausweis_2'] }}</button>
                  </h5>
                </div>

                <div id="iaDiv" class="collapse" aria-labelledby="headingIA">
                  <div class="card-body">
                    <table class="table">
                      <tbody>
                        <tr>
                          <td>Datei</td>
                        </tr>
                        <tr *ngFor="let file of aData['iaFileList']">
                          <td>{{ file }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <!-- Ausweisfoto -->
            <!--<div class="data-div">
              <div class="card conclusion-card">

                <div class="card-header conclusion-card-header" [ngStyle]="{'background-color': style['s']}" id="headingFoto" *ngIf="fotoToggle">
                  <h5 class="mb-0">
                    <button (click)="onCollapseableClick(11)" class="btn btn-link conclusion-card-button font-1 conclusion-card-header-button" data-toggle="collapse" data-target="#fotoDiv" aria-expanded="false" aria-controls="fotoDiv"><i class="fas fa-arrow-down conclusion-card-icon"></i>Ausweisfoto</button>
                  </h5>
                </div>

                <div class="card-header conclusion-card-header-active" [ngStyle]="{'background-color': style['p']}" id="headingFoto" *ngIf="!fotoToggle">
                  <h5 class="mb-0">
                    <button (click)="onCollapseableClick(11)" class="btn btn-link conclusion-card-button font-1 conclusion-card-header-button" data-toggle="collapse" data-target="#fotoDiv" aria-expanded="false" aria-controls="fotoDiv"><i class="fas fa-arrow-up conclusion-card-icon"></i>Ausweisfoto</button>
                  </h5>
                </div>

                <div id="fotoDiv" class="collapse" aria-labelledby="headingFoto">
                  <div class="card-body">
                    <table class="table">
                      <tbody>
                        <tr>
                          <td>Datei</td>
                        </tr>
                        <tr *ngFor="let file of aData['aFileList']">
                          <td>{{ file }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>-->
          </div>

          <div *ngIf="!aData['isGuest']">
            <p class="font-4">{{ text['P14_Netz'] }}</p>
            <div class="data-div">
              <div class="card conclusion-card">
                <div class="card-header conclusion-card-header" [ngStyle]="{'background-color': style['s']}" id="headingCompany" *ngIf="companyToggle && style">
                  <h5 class="mb-0">
                    <button (click)="onCollapseableClick(1)" class="btn btn-link conclusion-card-button font-1 conclusion-card-header-button" data-toggle="collapse" data-target="#companyDiv" aria-expanded="false" aria-controls="companyDiv"><i class="fas fa-arrow-down conclusion-card-icon"></i>{{ text['P14_Button_Toggle_Unternehmen_1'] }}</button>
                  </h5>
                </div>

                <div class="card-header conclusion-card-header-active" [ngStyle]="{'background-color': style['p']}" id="headingCompany" *ngIf="!companyToggle && style">
                  <h5 class="mb-0">
                    <button (click)="onCollapseableClick(1)" class="btn btn-link conclusion-card-button font-1 conclusion-card-header-button" data-toggle="collapse" data-target="#companyDiv" aria-expanded="false" aria-controls="companyDiv"><i class="fas fa-arrow-up conclusion-card-icon"></i>{{ text['P14_Button_Toggle_Unternehmen_2'] }}</button>
                  </h5>
                </div>

                <div id="companyDiv" class="collapse" aria-labelledby="headingCompany">
                  <div class="card-body">
                    <table class="table">
                      <tbody>
                        <tr>
                          <td class="td-left">Name:</td>
                          <td class="td-right">{{ aData['cName'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Straße:</td>
                          <td class="td-right">{{ aData['cStreet'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Hausnummer:</td>
                          <td class="td-right">{{ aData['cHouseNumber'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Postleitzahl:</td>
                          <td class="td-right">{{ aData['cZip'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Stadt:</td>
                          <td class="td-right">{{ aData['cCity'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Telefon:</td>
                          <td class="td-right">{{ aData['cPhoneNumber'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">E-Mail Adresse:</td>
                          <td class="td-right">{{ aData['cMail'] }}</td>
                        </tr>
                        <tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div class="data-div">
              <div class="card conclusion-card">
                <div class="card-header conclusion-card-header" [ngStyle]="{'background-color': style['s']}" id="headingWorkshop" *ngIf="workshopToggle">
                  <h5 class="mb-0">
                    <button (click)="onCollapseableClick(2)" class="btn btn-link conclusion-card-button font-1 conclusion-card-header-button" data-toggle="collapse" data-target="#workshopDiv" aria-expanded="false" aria-controls="workshopDiv"><i class="fas fa-arrow-down conclusion-card-icon"></i>{{ text['P14_Button_Toggle_Werkstatt_1'] }}</button>
                  </h5>
                </div>

                <div class="card-header conclusion-card-header-active" [ngStyle]="{'background-color': style['p']}" id="headingWorkshop" *ngIf="!workshopToggle">
                  <h5 class="mb-0">
                    <button (click)="onCollapseableClick(2)" class="btn btn-link conclusion-card-button font-1 conclusion-card-header-button" data-toggle="collapse" data-target="#workshopDiv" aria-expanded="false" aria-controls="workshopDiv"><i class="fas fa-arrow-up conclusion-card-icon"></i>{{ text['P14_Button_Toggle_Werkstatt_2'] }}</button>
                  </h5>
                </div>

                <div id="workshopDiv" class="collapse" aria-labelledby="headingWorkshop">
                  <div class="card-body">
                    <table *ngIf="!aData['wToggle']" class="table">
                      <tbody>
                        <tr>
                          <td class="td-left">Name:</td>
                          <td class="td-right">{{ aData['cName'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Straße:</td>
                          <td class="td-right">{{ aData['cStreet'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Hausnummer:</td>
                          <td class="td-right">{{ aData['cHouseNumber'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Postleitzahl:</td>
                          <td class="td-right">{{ aData['cZip'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Stadt:</td>
                          <td class="td-right">{{ aData['cCity'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Werkstatt eingerichtet:</td>
                          <td class="td-right">{{ aData['wFitted'] == true ? 'Ja' : 'Nein' }}</td>
                        </tr>
                        <tr>
                      </tbody>
                    </table>

                    <table *ngIf="aData['wToggle']" class="table">
                      <tbody>
                        <tr>
                          <td class="td-left">Name:</td>
                          <td class="td-right">{{ aData['wName'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Straße:</td>
                          <td class="td-right">{{ aData['wStreet'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Hausnummer:</td>
                          <td class="td-right">{{ aData['wHouseNumber'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Postleitzahl:</td>
                          <td class="td-right">{{ aData['wZip'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Stadt:</td>
                          <td class="td-right">{{ aData['wCity'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Werkstatt eingerichtet:</td>
                          <td class="td-right">{{ aData['wFitted'] == true ? 'Ja' : 'Nein' }}</td>
                        </tr>
                        <tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div class="data-div">
              <div class="card conclusion-card">
                <div class="card-header conclusion-card-header" [ngStyle]="{'background-color': style['s']}" id="headingOwner" *ngIf="ownerToggle">
                  <h5 class="mb-0">
                    <button (click)="onCollapseableClick(3)" class="btn btn-link conclusion-card-button font-1 conclusion-card-header-button" data-toggle="collapse" data-target="#ownerDiv" aria-expanded="false" aria-controls="ownerDiv"><i class="fas fa-arrow-down conclusion-card-icon"></i>{{ text['P14_Button_Toggle_Inhaber_1'] }}</button>
                  </h5>
                </div>

                <div class="card-header conclusion-card-header-active" [ngStyle]="{'background-color': style['p']}" id="headingOwner" *ngIf="!ownerToggle">
                  <h5 class="mb-0">
                    <button (click)="onCollapseableClick(3)" class="btn btn-link conclusion-card-button font-1 conclusion-card-header-button" data-toggle="collapse" data-target="#ownerDiv" aria-expanded="false" aria-controls="ownerDiv"><i class="fas fa-arrow-up conclusion-card-icon"></i>{{ text['P14_Button_Toggle_Inhaber_2'] }}</button>
                  </h5>
                </div>

                <div id="ownerDiv" class="collapse" aria-labelledby="headingOwner">
                  <div class="card-body">
                    <table class="table">
                      <tbody>
                        <tr>
                          <td class="td-left">Anrede:</td>
                          <td class="td-right">{{ aData['oSalutation'] == 1 ? 'Frau' : 'Herr' }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Titel:</td>
                          <td class="td-right">{{ aData['oTitle'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Vorname:</td>
                          <td class="td-right">{{ aData['oFirstName'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Nachname:</td>
                          <td class="td-right">{{ aData['oLastName'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Telefon:</td>
                          <td class="td-right">{{ aData['oPhoneNumber'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Mobil:</td>
                          <td class="td-right">{{ aData['oMobileNumber'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">E-Mail:</td>
                          <td class="td-right">{{ aData['oMail'] }}</td>
                        </tr>
                        <tr>
                      </tbody>
                    </table>

                  </div>
                </div>
              </div>
            </div>

            <div class="data-div">
              <div class="card conclusion-card">
                <div class="card-header conclusion-card-header" [ngStyle]="{'background-color': style['s']}" id="headingExpert" *ngIf="expertToggle">
                  <h5 class="mb-0">
                    <button (click)="onCollapseableClick(4)" class="btn btn-link conclusion-card-button font-1 conclusion-card-header-button" data-toggle="collapse" data-target="#expertDiv" aria-expanded="false" aria-controls="expertDiv"><i class="fas fa-arrow-down conclusion-card-icon"></i>{{ text['P14_Button_Toggle_Fachkraft_1'] }}</button>
                  </h5>
                </div>

                <div class="card-header conclusion-card-header-active" [ngStyle]="{'background-color': style['p']}" id="headingExpert" *ngIf="!expertToggle">
                  <h5 class="mb-0">
                    <button (click)="onCollapseableClick(4)" class="btn btn-link conclusion-card-button font-1 conclusion-card-header-button" data-toggle="collapse" data-target="#expertDiv" aria-expanded="false" aria-controls="expertDiv"><i class="fas fa-arrow-up conclusion-card-icon"></i>{{ text['P14_Button_Toggle_Fachkraft_2'] }}</button>
                  </h5>
                </div>

                <div id="expertDiv" class="collapse" aria-labelledby="headingExpert">
                  <div class="card-body">
                    <table class="table" *ngIf="aData['eToggleState']">
                      <tbody>
                        <tr>
                          <td class="td-left">Anrede:</td>
                          <td class="td-right">{{ aData['oSalutation'] == 1 ? 'Frau' : 'Herr' }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Titel:</td>
                          <td class="td-right">{{ aData['oTitle'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Vorname:</td>
                          <td class="td-right">{{ aData['oFirstName'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Nachname:</td>
                          <td class="td-right">{{ aData['oLastName'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Telefon:</td>
                          <td class="td-right">{{ aData['oPhoneNumber'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Mobil:</td>
                          <td class="td-right">{{ aData['oMobileNumber'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">E-Mail:</td>
                          <td class="td-right">{{ aData['oMail'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Geburtsdatum:</td>
                          <td class="td-right">{{ aData['eBirthday'] | date: 'dd.MM.yyyy' }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Ausübungsbeschränkung:</td>
                          <td class="td-right">{{ aData['eRestriction'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Ausübungsfrist:</td>
                          <td class="td-right">{{ aData['eExercisePeriod'] | date: 'dd.MM.yyyy' }}</td>
                        </tr>
                        <tr>
                      </tbody>
                    </table>

                    <table class="table" *ngIf="!aData['eToggleState']">
                      <tbody>
                        <tr>
                          <td class="td-left">Anrede:</td>
                          <td class="td-right">{{ aData['eSalutation'] == 1 ? 'Frau' : 'Herr' }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Titel:</td>
                          <td class="td-right">{{ aData['eTitle'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Vorname:</td>
                          <td class="td-right">{{ aData['eFirstName'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Nachname:</td>
                          <td class="td-right">{{ aData['eLastName'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Telefon:</td>
                          <td class="td-right">{{ aData['ePhoneNumber'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Mobil:</td>
                          <td class="td-right">{{ aData['eMobileNumber'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">E-Mail:</td>
                          <td class="td-right">{{ aData['eMail'] }}</td>
                        </tr>
                        <tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div class="data-div">
              <div class="card conclusion-card">
                <div class="card-header conclusion-card-header" [ngStyle]="{'background-color': style['s']}" id="headingEc" *ngIf="ecToggle">
                  <h5 class="mb-0">
                    <button (click)="onCollapseableClick(14)" class="btn btn-link conclusion-card-button font-1 conclusion-card-header-button" data-toggle="collapse" data-target="#ecDiv" aria-expanded="false" aria-controls="ecDiv"><i class="fas fa-arrow-down conclusion-card-icon"></i>{{ text['P14_Button_Toggle_Arbeitsvertrag_1'] }}</button>
                  </h5>
                </div>

                <div class="card-header conclusion-card-header-active" [ngStyle]="{'background-color': style['p']}" id="headingEc" *ngIf="!ecToggle">
                  <h5 class="mb-0">
                    <button (click)="onCollapseableClick(14)" class="btn btn-link conclusion-card-button font-1 conclusion-card-header-button" data-toggle="collapse" data-target="#ecDiv" aria-expanded="false" aria-controls="ecDiv"><i class="fas fa-arrow-up conclusion-card-icon"></i>{{ text['P14_Button_Toggle_Arbeitsvertrag_2'] }}</button>
                  </h5>
                </div>

                <div id="ecDiv" class="collapse" aria-labelledby="headingEc">
                  <div class="card-body">
                    <table class="table">
                      <tbody>
                        <tr>
                          <td>Datei</td>
                        </tr>
                        <tr *ngFor="let file of aData['ecFileList']">
                          <td>{{ file }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div class="data-div">
              <div class="card conclusion-card">
                <div class="card-header conclusion-card-header" [ngStyle]="{'background-color': style['s']}" id="headingQn" *ngIf="qnToggle">
                  <h5 class="mb-0">
                    <button (click)="onCollapseableClick(13)" class="btn btn-link conclusion-card-button font-1 conclusion-card-header-button" data-toggle="collapse" data-target="#qnDiv" aria-expanded="false" aria-controls="qnDiv"><i class="fas fa-arrow-down conclusion-card-icon"></i>{{ text['P14_Button_Toggle_QFN_1'] }}</button>
                  </h5>
                </div>

                <div class="card-header conclusion-card-header-active" [ngStyle]="{'background-color': style['p']}" id="headingQn" *ngIf="!qnToggle">
                  <h5 class="mb-0">
                    <button (click)="onCollapseableClick(13)" class="btn btn-link conclusion-card-button font-1 conclusion-card-header-button" data-toggle="collapse" data-target="#qnDiv" aria-expanded="false" aria-controls="qnDiv"><i class="fas fa-arrow-up conclusion-card-icon"></i>{{ text['P14_Button_Toggle_QFN_2'] }}</button>
                  </h5>
                </div>

                <div id="qnDiv" class="collapse" aria-labelledby="headingQn">
                  <div class="card-body">
                    <table class="table">
                      <tbody>
                        <tr>
                          <td>Datei</td>
                        </tr>
                        <tr *ngFor="let file of aData['qnFileList']">
                          <td>{{ file }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div class="data-div">
              <div class="card conclusion-card">
                <div class="card-header conclusion-card-header" [ngStyle]="{'background-color': style['s']}" id="headingIhk" *ngIf="ihkToggle">
                  <h5 class="mb-0">
                    <button (click)="onCollapseableClick(5)" class="btn btn-link conclusion-card-button font-1 conclusion-card-header-button" data-toggle="collapse" data-target="#ihkDiv" aria-expanded="false" aria-controls="ihkDiv"><i class="fas fa-arrow-down conclusion-card-icon"></i>{{ text['P14_Button_Toggle_Handwerksrolle_1'] }}</button>
                  </h5>
                </div>

                <div class="card-header conclusion-card-header-active" [ngStyle]="{'background-color': style['p']}" id="headingIhk" *ngIf="!ihkToggle">
                  <h5 class="mb-0">
                    <button (click)="onCollapseableClick(5)" class="btn btn-link conclusion-card-button font-1 conclusion-card-header-button" data-toggle="collapse" data-target="#ihkDiv" aria-expanded="false" aria-controls="ihkDiv"><i class="fas fa-arrow-up conclusion-card-icon"></i>{{ text['P14_Button_Toggle_Handwerksrolle_2'] }}</button>
                  </h5>
                </div>

                <div id="ihkDiv" class="collapse" aria-labelledby="headingIhk">
                  <div class="card-body">
                    <table class="table">
                      <tbody>
                        <tr>
                          <td>Datei</td>
                        </tr>
                        <tr *ngFor="let file of aData['ihkFileList']">
                          <td>{{ file }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div class="data-div">
              <div class="card conclusion-card">
                <div class="card-header conclusion-card-header" [ngStyle]="{'background-color': style['s']}" id="headingGewerbe" *ngIf="gewerbeToggle">
                  <h5 class="mb-0">
                    <button (click)="onCollapseableClick(6)" class="btn btn-link conclusion-card-button font-1 conclusion-card-header-button" data-toggle="collapse" data-target="#gewerbeDiv" aria-expanded="false" aria-controls="gewerbeDiv"><i class="fas fa-arrow-down conclusion-card-icon"></i>{{ text['P14_Button_Toggle_Gewerbeanmeldung_1'] }}</button>
                  </h5>
                </div>

                <div class="card-header conclusion-card-header-active" [ngStyle]="{'background-color': style['p']}" id="headingGewerbe" *ngIf="!gewerbeToggle">
                  <h5 class="mb-0">
                    <button (click)="onCollapseableClick(6)" class="btn btn-link conclusion-card-button font-1 conclusion-card-header-button" data-toggle="collapse" data-target="#gewerbeDiv" aria-expanded="false" aria-controls="gewerbeDiv"><i class="fas fa-arrow-up conclusion-card-icon"></i>{{ text['P14_Button_Toggle_Gewerbeanmeldung_2'] }}</button>
                  </h5>
                </div>

                <div id="gewerbeDiv" class="collapse" aria-labelledby="headingGewerbe">
                  <div class="card-body">
                    <table class="table">
                      <tbody>
                        <tr>
                          <td>Datei</td>
                        </tr>
                        <tr *ngFor="let file of aData['gFileList']">
                          <td>{{ file }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div class="data-div">
              <div class="card conclusion-card">
                <div class="card-header conclusion-card-header" [ngStyle]="{'background-color': style['s']}" id="headingInnung" *ngIf="innungToggle">
                  <h5 class="mb-0">
                    <button (click)="onCollapseableClick(7)" class="btn btn-link conclusion-card-button font-1 conclusion-card-header-button" data-toggle="collapse" data-target="#innungDiv" aria-expanded="false" aria-controls="innungDiv"><i class="fas fa-arrow-down conclusion-card-icon"></i>{{ text['P14_Button_Toggle_Innung_1'] }}</button>
                  </h5>
                </div>

                <div class="card-header conclusion-card-header-active" [ngStyle]="{'background-color': style['p']}" id="headingInnung" *ngIf="!innungToggle">
                  <h5 class="mb-0">
                    <button (click)="onCollapseableClick(7)" class="btn btn-link conclusion-card-button font-1 conclusion-card-header-button" data-toggle="collapse" data-target="#innungDiv" aria-expanded="false" aria-controls="innungDiv"><i class="fas fa-arrow-up conclusion-card-icon"></i>{{ text['P14_Button_Toggle_Innung_2'] }}</button>
                  </h5>
                </div>

                <div id="innungDiv" class="collapse" aria-labelledby="headingInnung">
                  <div class="card-body">
                    <table class="table">
                      <tbody>
                        <tr>
                          <td class="td-left">Mitglied in der Handwerksinnung:</td>
                          <td class="td-right">{{ aData['iToggleState'] == true ? 'Ja' : 'Nein' }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div class="data-div">
              <div class="card conclusion-card">
                <div class="card-header conclusion-card-header" [ngStyle]="{'background-color': style['s']}" id="headingHr" *ngIf="hrToggle">
                  <h5 class="mb-0">
                    <button (click)="onCollapseableClick(8)" class="btn btn-link conclusion-card-button font-1 conclusion-card-header-button" data-toggle="collapse" data-target="#hrDiv" aria-expanded="false" aria-controls="hrDiv"><i class="fas fa-arrow-down conclusion-card-icon"></i>{{ text['P14_Button_Toggle_Handelsregister_1'] }}</button>
                  </h5>
                </div>

                <div class="card-header conclusion-card-header-active" [ngStyle]="{'background-color': style['p']}" id="headingHr" *ngIf="!hrToggle">
                  <h5 class="mb-0">
                    <button (click)="onCollapseableClick(8)" class="btn btn-link conclusion-card-button font-1 conclusion-card-header-button" data-toggle="collapse" data-target="#hrDiv" aria-expanded="false" aria-controls="hrDiv"><i class="fas fa-arrow-up conclusion-card-icon"></i>{{ text['P14_Button_Toggle_Handelsregister_2'] }}</button>
                  </h5>
                </div>

                <div id="hrDiv" class="collapse" aria-labelledby="headingHr">
                  <div class="card-body">
                    <table class="table" *ngIf="aData['hToggleState']">
                      <tbody>
                        <tr>
                          <td class="td-left">Eintrag vorhanden:</td>
                          <td class="td-right">Ja</td>
                        </tr>
                        <tr>
                          <td class="td-left">Handelsregisternummer:</td>
                          <td class="td-right">{{ aData['hHrNummer'] }}</td>
                        </tr>
                        <tr>
                          <td class="td-left">Amtsgericht:</td>
                          <td class="td-right">{{ aData['hAmtgericht'] }}</td>
                        </tr>
                      </tbody>
                    </table>

                    <table class="table" *ngIf="!aData['hToggleState']">
                      <tbody>
                        <tr>
                          <td class="td-left">Eintrag vorhanden:</td>
                          <td class="td-right">Nein</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div class="data-div">
              <div class="card conclusion-card">
                <div class="card-header conclusion-card-header" [ngStyle]="{'background-color': style['s']}" id="headingUnfall" *ngIf="unfallToggle">
                  <h5 class="mb-0">
                    <button (click)="onCollapseableClick(9)" class="btn btn-link conclusion-card-button font-1 conclusion-card-header-button" data-toggle="collapse" data-target="#unfallDiv" aria-expanded="false" aria-controls="unfallDiv"><i class="fas fa-arrow-down conclusion-card-icon"></i>{{ text['P14_Button_Toggle_Unfallkasse_1'] }}</button>
                  </h5>
                </div>

                <div class="card-header conclusion-card-header-active" [ngStyle]="{'background-color': style['p']}" id="headingUnfall" *ngIf="!unfallToggle">
                  <h5 class="mb-0">
                    <button (click)="onCollapseableClick(9)" class="btn btn-link conclusion-card-button font-1 conclusion-card-header-button" data-toggle="collapse" data-target="#unfallDiv" aria-expanded="false" aria-controls="unfallDiv"><i class="fas fa-arrow-up conclusion-card-icon"></i>{{ text['P14_Button_Toggle_Unfallkasse_2'] }}</button>
                  </h5>
                </div>

                <div id="unfallDiv" class="collapse" aria-labelledby="headingUnfall">
                  <div class="card-body">
                    <table class="table">
                      <tbody>
                        <tr>
                          <td class="td-left">Unfallkasse:</td>
                          <td class="td-right">{{ aData['unfallkasse'] }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div class="data-div">
              <div class="card conclusion-card">
                <div class="card-header conclusion-card-header" [ngStyle]="{'background-color': style['s']}"  id="headingHpv" *ngIf="hpvToggle">
                  <h5 class="mb-0">
                    <button (click)="onCollapseableClick(10)" class="btn btn-link conclusion-card-button font-1 conclusion-card-header-button" data-toggle="collapse" data-target="#hpvDiv" aria-expanded="false" aria-controls="hpvDiv"><i class="fas fa-arrow-down conclusion-card-icon"></i>{{ text['P14_Button_Toggle_BTHPV_1'] }}</button>
                  </h5>
                </div>

                <div class="card-header conclusion-card-header-active" [ngStyle]="{'background-color': style['p']}" id="headingHpv" *ngIf="!hpvToggle">
                  <h5 class="mb-0">
                    <button (click)="onCollapseableClick(10)" class="btn btn-link conclusion-card-button font-1 conclusion-card-header-button" data-toggle="collapse" data-target="#hpvDiv" aria-expanded="false" aria-controls="hpvDiv"><i class="fas fa-arrow-up conclusion-card-icon"></i>{{ text['P14_Button_Toggle_BTHPV_2'] }}</button>
                  </h5>
                </div>

                <div id="hpvDiv" class="collapse" aria-labelledby="headingHpv">
                  <div class="card-body">
                    <table class="table">
                      <tbody>
                        <tr>
                          <td>Datei</td>
                        </tr>
                        <tr *ngFor="let file of aData['hpvFileList']">
                          <td>{{ file }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <!-- Ausweisfoto -->
            <!--<div class="data-div">
              <div class="card conclusion-card">

                <div class="card-header conclusion-card-header" [ngStyle]="{'background-color': style['s']}" id="headingFoto" *ngIf="fotoToggle">
                  <h5 class="mb-0">
                    <button (click)="onCollapseableClick(11)" class="btn btn-link conclusion-card-button font-1 conclusion-card-header-button" data-toggle="collapse" data-target="#fotoDiv" aria-expanded="false" aria-controls="fotoDiv"><i class="fas fa-arrow-down conclusion-card-icon"></i>Ausweisfoto</button>
                  </h5>
                </div>

                <div class="card-header conclusion-card-header-active" [ngStyle]="{'background-color': style['p']}" id="headingFoto" *ngIf="!fotoToggle">
                  <h5 class="mb-0">
                    <button (click)="onCollapseableClick(11)" class="btn btn-link conclusion-card-button font-1 conclusion-card-header-button" data-toggle="collapse" data-target="#fotoDiv" aria-expanded="false" aria-controls="fotoDiv"><i class="fas fa-arrow-up conclusion-card-icon"></i>Ausweisfoto</button>
                  </h5>
                </div>

                <div id="fotoDiv" class="collapse" aria-labelledby="headingFoto">
                  <div class="card-body">
                    <table class="table">
                      <tbody>
                        <tr>
                          <td>Datei</td>
                        </tr>
                        <tr *ngFor="let file of aData['aFileList']">
                          <td>{{ file }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>-->

          </div>
        </div>

        <div class="col-sm-12 col-md-6 wizard-col form-group-IV">
          <button *ngIf="style" [ngStyle]="{'background-color': style['t']}" (click)="onBack()" class="btn btn-prev-step btn-full-size btn-big text-bold font-1 shadow-IV">{{ text['P14_Button_Abort'] }}</button>
        </div>

        <div class="col-sm-12 col-md-6 wizard-col form-group-IV">
          <button *ngIf="style" [ngStyle]="{'background-color': style['p']}" class="btn btn-next-step btn-full-size btn-big text-bold font-1 shadow-IV" (click)="onSubmit()">{{ Button_Message }}</button>
        </div>
      </div>
    </div>

    <div class="progress">
      <div class="progress-bar" role="progressbar" style="width: 100%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" *ngIf="style" [ngStyle]="{'background-color': style['p']}"></div>
    </div>
  </div>
</div>
