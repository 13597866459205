import {Component, OnInit} from '@angular/core';
import {StyleService} from '../../services/style.service';
import {TextService} from '../../services/text.service';
import {IdentityService} from '../../services/identity.service';
import {environment} from '../../../environments/environment';
import {StyleModel} from '../../models/style.model';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss']
})

export class InfoModalComponent implements OnInit {
  public text;
  public style: StyleModel = new StyleModel();
  public subdomain: string;
  public infoUrl;

  constructor(
    private _textService: TextService,
    private _styleService: StyleService,
    private _identityService: IdentityService
  ) {}

  public ngOnInit(): void {
    this._textService.text.subscribe(data => {
      this.text = data;
    });

    this._styleService.styleObservable.subscribe((style: StyleModel) => {
      this.style = style;
    });

    this._identityService.subdomain.subscribe((subdomain: string) => {
      this.subdomain = subdomain;
      this.infoUrl = environment.apiUrl + '/Clients/GetTenantFile/';
    });
  }
}
