import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms'
import {Router} from '@angular/router';
import {DataService} from '../../services/data.service';
import {OnlyNumbers} from '../../validators/only_numbers';
import {OnlyCharacters} from '../../validators/only_characters';
import {TextService} from '../../services/text.service';
import {StyleService} from '../../services/style.service';
import {StyleModel} from '../../models/style.model';
import {ApplicationModel} from '../../models/application.model';

@Component({
  selector: 'app-owner',
  templateUrl: './owner.component.html',
  styleUrls: ['./owner.component.scss']
})

export class OwnerComponent implements OnInit {
  public message: string = 'Angaben zum Inhaber oder Geschäftsführer des Unternehmens';
  public form: FormGroup;
  public submitted: boolean = false;
  public application: ApplicationModel;
  public text;
  public style: StyleModel;

  constructor(
    private fb: FormBuilder,
    private _router: Router,
    private _dataService: DataService,
    private _textService: TextService,
    private _styleService: StyleService
  ) {}

  public ngOnInit(): void {
    this.application = this._dataService.data;
    this.form = this.getOwnerForm(this.application);
    this.subscribeToTextData();
    this.subscribeToStyleData();
  }

  private getOwnerForm(application: ApplicationModel): FormGroup {
    return new FormGroup({
      salutation: new FormControl(application.oSalutation, [Validators.required, Validators.min(1)]),
      title: new FormControl(application.oTitle),
      firstName: new FormControl(application.oFirstName, [Validators.required, OnlyCharacters]),
      lastName: new FormControl(application.oLastName, [Validators.required, OnlyCharacters]),
      overtakeCompanyValues: new FormControl(false),
      phoneNumber: new FormControl(application.oPhoneNumber, [Validators.required, OnlyNumbers]),
      mobileNumber: new FormControl(application.oMobileNumber, OnlyNumbers),
      mail: new FormControl(application.oMail, [Validators.required, Validators.email])
    });
  }

  private subscribeToTextData(): void {
    this._textService.text
      .subscribe(text => {
        this.text = text;
      });
  }

  private subscribeToStyleData(): void {
    this._styleService.styleObservable
      .subscribe((style: StyleModel): void => {
        this.style = style;
      });
  }

  public onOvertakeCompanyValuesChange(checked: boolean): void {
    const overtakenCompanyPhoneNumber: string = checked ? this.application.cPhoneNumber : null;
    const overtakenCompanyEmail: string = checked ? this.application.cMail : null;
    this.form.get('phoneNumber').reset(overtakenCompanyPhoneNumber);
    this.form.get('mail').reset(overtakenCompanyEmail);
    this.changeMe('oPhoneNumber', overtakenCompanyPhoneNumber);
    this.changeMe('oMail', overtakenCompanyEmail);
  }

  public changeMe(key: string, value: string): void {
    this._dataService.updateData(key, value);
  }

  public onSubmit(): void {
    this.submitted = true;

    if (this.form.valid) {
      this._router.navigate(['/expert']);
    }
  }

  public onBack() {
    this._router.navigate(['/workshop']);
  }
}
