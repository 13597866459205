import {Component} from '@angular/core';
import {StyleService} from '../../services/style.service';
import {WebsiteRefersService} from '../../services/website-refers.service';
import {WebsiteRefersModel} from '../../models/website-refers.model';
import {StyleModel} from "../../models/style.model";

@Component({
  selector: 'app-imprint-and-data-protection-links',
  templateUrl: './imprint-and-data-protection-links.component.html',
  styleUrls: ['./imprint-and-data-protection-links.component.scss']
})

export class ImprintAndDataProtectionLinksComponent {
  public style: StyleModel = new StyleModel();
  public websiteRefersModel: WebsiteRefersModel;

  constructor(
    private _styleService: StyleService,
    private _websiteRefersService: WebsiteRefersService
  ) {
    this._styleService.styleObservable.subscribe((style: StyleModel) => {
      try {
        this.style = style;
      } catch (e) {}
    });

    this._websiteRefersService.refers.subscribe((refers: WebsiteRefersModel) => {
      try {
        this.websiteRefersModel = refers;
      } catch (e) {
      }
    });
  }

  public navigateToUrl(url: string) {
    window.open(url,'_blank');
  }
}
