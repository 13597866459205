import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {OwnerComponent} from './owner.component';
import {InfoModalModule} from '../../dialogs/info-modal/info-modal.module';

@NgModule({
  declarations: [
    OwnerComponent
  ],
  exports: [
    OwnerComponent
  ],
  imports: [
    CommonModule,
    InfoModalModule,
    ReactiveFormsModule,
    MatCheckboxModule
  ]
})

export class OwnerModule {}
