import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {DataService} from '../../services/data.service';
import {TextService} from 'src/app/services/text.service';
import {StyleService} from 'src/app/services/style.service';
import {StyleModel} from "../../models/style.model";

@Component({
  selector: 'app-handelsregister',
  templateUrl: './handelsregister.component.html',
  styleUrls: ['./handelsregister.component.scss']
})

export class HandelsregisterComponent implements OnInit {
  switch_button_state: boolean = false;
  HrForm: FormGroup;
  submitted: boolean = false;
  success: boolean = false;
  myRouter: Router;
  message: string = "Eingaben zum Handelsregistereintrag tätigen!";
  aData = null;
  text;
  public style: StyleModel;

  constructor(private fb: FormBuilder, private router: Router, private data: DataService, TextService: TextService, private _styleService: StyleService) {
    this.aData = data.data;
    this.switch_button_state = this.aData['hToggleState'];
    this.HrForm = this.fb.group({
      hrNummer: [this.aData['hHrNummer'], Validators.required],
      amtgericht: [this.aData['hAmtgericht'], Validators.required],
    });

    this.myRouter = this.router;

    TextService.text.subscribe(data => {
      this.text = data;
    });

    this._styleService.styleObservable.subscribe((style: StyleModel) => {
      this.style = style;
    });
  }

  ngOnInit() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  toggleState() {
    this.switch_button_state = !this.switch_button_state;
    this.data.updateData('hToggleState', this.switch_button_state);
  }

  changeMe(control: string, event) {
    this.data.updateData(control, event.target.value);
  }

  onSubmit() {
    this.submitted = true;

    if (this.switch_button_state && this.HrForm.valid ) {
      this.router.navigate(['/unfallkasse']);
    } else if (!this.switch_button_state) {
      this.router.navigate(['/unfallkasse']);
    }
  }

  onBack() {
    this.router.navigate(['/innung']);
  }
}
