import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {DataService} from '../../services/data.service';
import {StyleService} from 'src/app/services/style.service';
import {TextService} from 'src/app/services/text.service';
import {MessageDialogComponent } from 'src/app/dialogs/message-dialog/message-dialog.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {StyleModel} from '../../models/style.model';

@Component({
  selector: 'app-ausweisfoto',
  templateUrl: './ausweisfoto.component.html',
  styleUrls: ['./ausweisfoto.component.scss']
})

export class AusweisfotoComponent implements OnInit {
  fileList: string[] = ['[Noch keine Datei ausgewählt]'];
  message: string = 'Bitte Datei auswählen!';
  submitted: boolean = false;
  valid: boolean = false;
  myRouter: Router;
  aData = null;
  public style: StyleModel;
  text = null;
  private messageDialogRef: MatDialogRef<MessageDialogComponent>;

  constructor(
    private router: Router,
    private data: DataService,
    private _styleService: StyleService,
    private textService: TextService,
    public dialog: MatDialog
  ) {
    this.aData = data.data;
    this.fileList = this.aData['aFileList'];
    this.myRouter = this.router;
  }

  ngOnInit() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    this._styleService.styleObservable.subscribe((style: StyleModel) => {
      this.style = style;
    });

    this.textService.text.subscribe(e => {
      this.text = e;
    });
  }

  onFileSelected(event) {
    if (!this.validateFile(event.target)) {
        this.messageDialogRef = this.dialog.open(MessageDialogComponent, {
          disableClose: false
        });
        this.messageDialogRef.componentInstance.message = `Bitte ein Foto mit einem Bildformat hochladen (z.B. png, jpg)`;
        this.messageDialogRef.componentInstance.title = `Ungültiges Dateiformat`;
        return;
    }

    this.fileList = [];

    let i = 0;
    if (event.target.files.length > 0) {
      for (i = 0; i < event.target.files.length; i++) {
        if (event.target.files[i].name == 'item' || event.target.files[i].name == 'undefined') {
        } else {
          this.fileList[i] = event.target.files[i].name;
        }
      }
    }
    this.data.updateData('aFileList', this.fileList);
    this.data.ausweisfotos_files = event.target.files;
  }

  validateFile(fileInput: any): boolean {
    return Array.prototype.every.call(fileInput.files, file => {
      const acceptedMimeTypes = fileInput.accept.split(',');

      return acceptedMimeTypes.some(mimeType => {
        const mimeTypeRegExp = new RegExp(mimeType.replace('*', '[^\\/,]+'));
        return mimeTypeRegExp.test(file.type);
      });
    });
  }

  onSubmit() {
    this.submitted = true;

    if (this.fileList.length > 0) {
      if (this.fileList[0] == '[Noch keine Datei ausgewählt]') {
        this.valid = false;
      } else {
        this.router.navigate(['/conclusion']);
      }
    }
  }

  onBack() {
    if (this.aData['isGuest']) {
      this.router.navigate(['/installateursausweis']);
    } else {
      this.router.navigate(['/haftpflichtversicherung']);
    }
  }
}
