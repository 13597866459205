import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {ExpertComponent} from './expert.component';
import {InfoModalModule} from '../../dialogs/info-modal/info-modal.module';

@NgModule({
  declarations: [
    ExpertComponent
  ],
  exports: [
    ExpertComponent
  ],
  imports: [
    CommonModule,
    InfoModalModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatCheckboxModule
  ]
})

export class ExpertModule {}
