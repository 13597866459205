<div *ngIf="websiteRefersModel?.imprintURL || websiteRefersModel?.dataProtectionURL" class="d-flex justify-content-center mt-4">
	<div class="d-flex" [ngStyle]="{'background-color': style['s']}">
		<div
			class="navigate-link"
			[class.imprint-link]="websiteRefersModel?.imprintURL && websiteRefersModel?.dataProtectionURL"
			*ngIf="websiteRefersModel?.imprintURL"
			(click)="navigateToUrl(websiteRefersModel.imprintURL)">
			Impressum
		</div>
		<div *ngIf="websiteRefersModel?.imprintURL && websiteRefersModel?.dataProtectionURL" class="divider">|</div>
		<div
			class="navigate-link"
			[class.data-protection-link]="websiteRefersModel?.imprintURL && websiteRefersModel?.dataProtectionURL"
			*ngIf="websiteRefersModel?.dataProtectionURL"
			(click)="navigateToUrl(websiteRefersModel.dataProtectionURL)">
			Datenschutz
		</div>
	</div>
</div>
